import React from "react"
import { Link, graphql } from "gatsby"
import { motion } from "framer-motion"
import { format, differenceInCalendarDays } from "date-fns"
import SEO from "../components/seo"

// Layout
import Layout from "../components/layout"
import Title from "../components/Layout/Title"
import { fr } from "date-fns/locale"

const variants = {
  visible: i => ({
    opacity: 1,
    transition: {
      type: "spring",
      delay: i * 0.1,
    },
  }),
  hidden: { opacity: 0 },
}

const Blog = ({ data }) => {
  const articles = data.allMarkdownRemark.edges

  return (
    <Layout headerType="standard">
      <SEO title="Blog" />
      <div className="page__header">
        <div className="container">
          <Title upper="Quoi" lower="de neuf ?" />
          <p style={{ fontWeight: 500 }}>
            Je partage avec vous mes découvertes, mes projets ainsi que mes
            passions. J'ai pour ambition d'être de plus en plus actif et pense
            faire de mieux en mieux.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="posts">
          {articles.map((article: any, index: number) => (
            <Link
              to={`blog${article.node.fields.slug}`}
              key={index}
              className="post">
              <motion.div
                // initial="hidden"
                // animate="visible"
                // variants={variants}
                // custom={index}
                className="post__body">
                <div className="post__infos">
                  <div className="post__date">
                    <span className="post__day">
                      {format(new Date(article.node.frontmatter.date), "d")}
                    </span>
                    <span className="post__month">
                      {format(new Date(article.node.frontmatter.date), "LLL", {
                        locale: fr,
                      })}
                    </span>
                  </div>
                  <ul className="badges__list">
                    {article.node.frontmatter.tags.map((tag: any, index: number) => (
                      <li className="badge badge--theme" key={index}>{tag}</li>
                    ))}
                  </ul>
                </div>
                <div className="post__content">
                  <h3>{article.node.frontmatter.title}</h3>
                  <p>{article.node.frontmatter.description}</p>
                </div>
                {differenceInCalendarDays(
                  new Date(),
                  new Date(article.node.frontmatter.date)
                ) <= 7 ? (
                    <div className="post__new">Nouveau</div>
                  ) : (
                    ""
                  )}
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </Layout >
  )
}

export const query = graphql`
  query Articles {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(format: PLAIN, truncate: true, pruneLength: 250)
          frontmatter {
            title
            description
            date(formatString: "")
            tags
          }
          timeToRead
        }
      }
    }
  }
`

export default Blog
