import React, { useState } from "react"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

interface Props {
  upper: string
  lower: string
  color?: "light" | "dark"
  highlight?: boolean
}

const Title = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false)

  const up = {
    visible: { x: 0, opacity: 1 },
    hidden: { x: 100, opacity: 0 },
  }
  const down = {
    visible: { x: 0, opacity: 1, transition: { delay: 0.2 } },
    hidden: { x: 100, opacity: 0, transition: { delay: 0.2 } },
  }

  return (
    <InView
      as="h2"
      threshold={0}
      triggerOnce={true}
      rootMargin="-100px"
      onChange={inView => setIsVisible(inView)}>
      <motion.span
        className={`title__upper title__upper--${props.color}`}
        animate={isVisible ? "visible" : "hidden"}
        variants={up}>
        {props.upper}
      </motion.span>
      <motion.span
        className="title__lower"
        animate={isVisible ? "visible" : "hidden"}
        variants={down}>
        {props.lower}
      </motion.span>
    </InView>
  )
}

export default Title
